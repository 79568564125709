// reveal modal
.is-reveal-open {
  position: static!important;
  body {
    overflow: visible!important;
  }
}
.reveal-overlay {
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.75);
}
.reveal {
  @include breakpoint(small) {
    height: auto;
    min-height: 0;
    max-width: 94%;
    margin: 0 auto;
  }
  &.reveal-video {
    background-color: $black;
    border: rem-calc(1) solid $black;
    width: 100%;
    overflow: hidden;
    padding: 0;
    border-radius: rem-calc(5);
    box-shadow: rem-calc(1) rem-calc(6) rem-calc(10) rgba(0, 0, 0, 0.25);
    top: 50% !important;
    transform: translateY(-50%);
    z-index: 9999;
    @include breakpoint(large) {
      max-width: rem-calc(1024);
    }
    .responsive-embed {
      padding-bottom: 56.25%;
      margin: 0;
      iframe {
        border: 0;
      }
    }
    .close-button {
      position: absolute;
      top: rem-calc(16);
      right: rem-calc(16);
      cursor: pointer;
      background-color: $white;
      border-radius: 50%;
      @include breakpoint(small) {
        width: rem-calc(32);
        height: rem-calc(32);
      }
      @include breakpoint(medium) {
        width: rem-calc(64);
        height: rem-calc(64);
      }

      svg {
        @include vertical-center;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include breakpoint(small) {
          width: rem-calc(16);
          height: rem-calc(16);
        }
        @include breakpoint(medium) {
          width: rem-calc(24);
          height: rem-calc(24);
        }
        .icon-close {
          fill: $black;
        }
      }
    }
    .video-transcript {
      text-align: center;
      .btn {
        display: inline-block;
        margin: rem-calc(16) auto;
        max-width: rem-calc(320);
        padding: rem-calc(16) rem-calc(32);
      }
    }
  }
  // role modal
  &.reveal-role {
    border: none;
    border-radius: rem-calc(40);
    box-shadow: rem-calc(5) rem-calc(5) rem-calc(15) rgba(0,0,0,0.35);
    max-width: rem-calc(800);
    padding: 0;
    width: 100%;
    .reveal-role-top {
      padding: rem-calc(32);
    }
    .reveal-role-bottom {
      background-color: $dark-grey;
      padding: rem-calc(32);
      .grid-x {
        justify-content: space-between;
        .reveal-close {
          text-align: right;
        }
      }
    }
  }
  // policy modal
  &.reveal-policy {
    .reveal-content {
      padding: rem-calc(50);
      p {
        font-size: rem-calc(16);
      }
      .btn {
        max-width: rem-calc(250);
      }
    }
  }
}
