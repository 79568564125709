#contact-options {
  border: 2px solid $black;
  padding: 10px;
}

.contact-form {
  display: none;

  .contact-form-intro {
    border: 2px solid $black;
    padding: rem-calc(32) rem-calc(48); 
    margin-bottom: rem-calc(32);  
    p {
      font-size: rem-calc(18);
      margin:0;
    } 
  }

  .contact-form-field {
    margin-bottom: rem-calc(16);
    label {
      display: block;
      font-weight: bold;
    }
    input:not([type="submit"]):not([type="checkbox"]), select, textarea {
      border: 2px solid $black;
      padding: 10px;
      width: 100%;
    }
  }

}