@import 'foundation';
@import '~foundation-sites/scss/util/util';
@import '~foundation-sites/scss/util/mixins';

@import './components/accordion';
@import './components/apply-list';
@import './components/article';
@import './components/background-image';
@import './components/breadcrumbs';
@import './components/buttons';
@import './components/card';
@import './components/colors';
@import './components/contact-form';
@import './components/divider';
@import './components/dual-column';
@import './components/footer';
@import './components/header';
@import './components/hero';
@import './components/icon';
@import './components/masonry';
@import './components/multi-image';
@import './components/reveal';
@import './components/single-role';
@import './components/slider';
@import './components/tabs';
@import './components/team';
@import './components/typography';
@import './components/vertical-tabs';
@import './components/video-block';
@import './fonts';
@import './functions';


@include padding-list();
@include margin-list();
@include tint-list();

#skip-to-content {
	padding: 1em 2em;
	position: absolute;
	top: -100%;
	left: 0		;
	width: 100%;
	z-index: 1000;
	&:focus {
		display: block;
	  position: static;
	  top: 0;
	  left:0;
	  background: $focus-color;
	}  
}

a:focus, button:focus {
	outline: 1px solid transparent!important;
	background-color: $focus-color!important;
	color: $black!important;	
	box-shadow: 0 0 $focus-color, 0 4px $black!important;
}

main{	
	overflow: hidden;
	position: relative;
	&:focus {
		outline: 1px solid transparent!important;
	}
}

// grid settings
.grid-relative, .cell-relative{
	position: relative;
}

// container overflow
.container-overflow {
	overflow: hidden;
}

// block content
.block-content {
  position: inherit;
  z-index: 1;
}

// transition all
.transition-all{
	transition: all 0.25s ease;
}

// policy table
.policy-table {
	border: 0;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%!important;
	height: auto!important;
	table-layout: fixed;
	@include breakpoint(medium){
		border: rem-calc(1) solid $light-grey;
	}
	thead {
		@include breakpoint(small only){
			border: none;
			clip: rect(0 0 0 0);
			height: rem-calc(1);
			margin: rem-calc(-1);
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: rem-calc(1);
		}
	}
	tr {
		background-color: $lightest-grey;
		border: rem-calc(1) solid $light-grey;
		padding: rem-calc(4);
		width: auto!important;
		height: auto!important;
		@include breakpoint(small only){
			border-bottom: rem-calc(3) solid $light-grey;
			display: block;
			margin-bottom: rem-calc(24);
		}
		th, td {
			padding: rem-calc(16);
			text-align: center;
			border: rem-calc(2) solid $white;
			width: auto!important;
			height: auto!important;
			font-size: rem-calc(14);
			p{
				margin: 0;
			}
		}
		th {
			background-color: $charcol;
			color: $white;
			letter-spacing: rem-calc(1);
			text-transform: uppercase;
			p{
				color: $white;
			}
		}
		td {
			@include breakpoint(small only){
				border:none;
				border-bottom: rem-calc(1) solid $light-grey;
				display: block;
				text-align: left;
			} 
			&:before {
				@include breakpoint(small only){
					content: attr(data-label);
					display: block;
					font-weight: bold;
					margin-bottom: rem-calc(8);
					text-transform: uppercase;
				} 
			}
			&:last-child {
				@include breakpoint(small only){
					border-bottom: 0;
				} 

			}
		}
	}
}

// block background image settings
.block-bg-img {
	background-repeat: no-repeat;
}
// block background image settings - size
.bg-s-auto {
	background-size: auto;
}
.bg-s-contain {
	background-size: contain;
}
.bg-s-cover {
	background-size: cover;
}
// block background image settings - position
.bg-p-top-center {
	background-position: top center;
}
.bg-p-top-left {
	background-position: top left;
}
.bg-p-top-right {
	background-position: top right;
}
.bg-p-center-center {
	background-position: center center;
}
.bg-p-center-left {
	background-position: center left;
}
.bg-p-center-right {
	background-position: center right;
}
.bg-p-bottom-center {
	background-position: bottom center;
}
.bg-p-bottom-left {
	background-position: bottom left;
}
.bg-p-bottom-right {
	background-position: bottom right;
}

// old browsers
.old-browsers {
	position: fixed;
	width:100%;
	height:100vh;
	background-color: $white;
	text-align: center;
	z-index: 10000;
	padding:100px 0; 
	p {
		width:100%;
		max-width: 640px;
		margin: 20px auto;
	}
	ul {
		list-style: none;
		margin: 20px 0;
		li {
			display: inline-block;
			margin: 20px;
			a {
				display: block;
			}
		}
	}
}

// images
.img-card-placeholder {
	background-image: url('/assets/images/img-card-placeholder.png');
}
.img-tabs-placeholder {
	background-image: url('/assets/images/img-tab-placeholder.png');
}
.img-chrome {
	background-image: url('/assets/images/chrome_128x128.png');
}
.img-msedge {
	background-image: url('/assets/images/edge_128x128.png');
}
.img-firefox {
	background-image: url('/assets/images/firefox_128x128.png');
}
.img-safari {
	background-image: url('/assets/images/safari_128x128.png');
}